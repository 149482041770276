import { Box, Typography } from '@mui/material'

const GoogleCalendarWidget = () => {
  return (
    <Box
      component="iframe"
      src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2quGRkocQSUWzkJGuGoBFtIxWKUG_O8fzrx9HzX4NgMBHlV8TAuHxuTfVMntqB0NnK4qP-Jhec?gv=true"
      sx={({ breakpoints }) => ({
        border: 0,
        width: '100%',
        height: '80rem',
        borderRadius: '1rem',
        overflowY: 'hidden',
        backgroundColor: 'white.main',
        [breakpoints.up('tablet')]: {
          height: '45rem',
        },
      })}
    />
  )
}

const FinancialCoachingSessions = ({ workerName }) => {
  return (
    <>
      <Typography
        variant="h3"
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
        })}
      >
        Coach Financiero
      </Typography>

      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(2) })}
      >
        <Typography variant="lead1" sx={{ color: 'accent4.main' }}>
          {workerName},
        </Typography>{' '}
        tu futuro se verá en HD con nuestro coach financiero. Anímate a
        solicitar una cita, dale con toda.
      </Typography>

      <GoogleCalendarWidget />
    </>
  )
}

export default FinancialCoachingSessions
