import http, { getApiURL } from '../httpService'

export const getAccountingCodes = (category = '', categories = []) => {
  const url = getApiURL({
    pathname: '/accounting/codes',
    searchParams: { categories, category },
  })

  return http.getV2(url)
}

export const createAccountingCodes = ({ accountingCodes }) => {
  const url = getApiURL({ pathname: '/accounting/code' })

  return http.putV2(url, accountingCodes)
}

export default {
  getAccountingCodes,
  createAccountingCodes,
}
