import { useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import useHolidaysService from 'utils/hooks/worker/holiday'

import { getHolidays } from 'services/worker/holidaysService'

import * as routes from 'config/routes'

import columnsData from './columnsData'

const WorkerHolidays = () => {
  const { worker } = useWorker()
  const { workerId } = useParams()
  const { enqueueDownload } = useDownloadManager()
  const { holidaysMutation } = useHolidaysService()

  const fetchHolidays = (pageIndex) => {
    return {
      queryKey: ['getHolidays', pageIndex + 1],
      queryFunction: () => getHolidays(workerId, { page: pageIndex + 1 }),
    }
  }

  function handleDownloadHolidaysHistoryFile() {
    enqueueDownload({
      name: `historial de vacaciones de ${worker.fullName}`,
      fileCode: 'holidays_history_file',
      firebasePath: `workers/${workerId}/holidays_history`,
      service: () =>
        holidaysMutation.mutateAsync({
          mutationMethod: 'GET',
          mutationKey: 'holidaysHistoryFile',
          workerId,
          fileFormat: 'xlsx',
        }),
    })
  }

  return (
    <Page
      documentTitle={`Historial de vacaciones${worker ? ` de ${worker.fullName}` : ''} `}
      header={
        <Typography
          variant="h2"
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          Historial de vacaciones
          {worker ? (
            <>
              {' '}
              de{' '}
              <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
            </>
          ) : null}
        </Typography>
      }
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Table
          columns={columnsData}
          data={fetchHolidays}
          actions={[
            {
              id: 'download_xlsx_file',
              tooltip: 'Descargar historial',
              icon: <Icon name="download" />,
              isFreeAction: true,
              onClick: handleDownloadHolidaysHistoryFile,
            },
          ]}
          options={{
            search: false,
            version: {
              toolbar: 'v2',
            },
          }}
        />
      </Box>
      <Box
        sx={{
          gridColumn: '1 / -1',
          fontSize: 'smaller',
          paddingTop: '5rem',
          paddingLeft: '1rem',
        }}
      >
        <ul>
          <li>
            Las vacaciones acumuladas del <strong>periodo actual</strong> se
            suman cuando{' '}
            <strong>
              se genera el período siguiente o cuando se liquida al empleado
            </strong>
            .
          </li>
          <li>
            Para ver el cálculo de las vacaciones acumuladas de cada período:
            Historial de períodos {'>'} Selecciona el período {'>'} Busca la
            persona {'>'} Click en Ver Cálculos {'>'} Provisión Prestaciones
            Sociales.
          </li>
          <li>
            Para ver el detalle de las vacaciones difrutadas y/o compensadas por
            la persona,{' '}
            <Link
              to={routes.WORKER_NOVELTIES_HISTORY(workerId)}
              color="accent4.main"
              variant="lead2"
              sx={{
                textDecoration: 'underline',
                '&:hover': {
                  color: 'accent4.light',
                },
              }}
            >
              haz click aquí.
            </Link>
          </li>
          <li>
            Aleluya se basa, en primer lugar, en los días de vacaciones
            disfrutadas que se registraron cuando{' '}
            <strong>se agregó el empleado a Aleluya</strong>, en la pregunta:
            &ldquo;¿Cuántos días de vacaciones ha disfrutado desde que
            ingresó?&rdquo;.
          </li>
        </ul>
      </Box>
    </Page>
  )
}

export default WorkerHolidays
