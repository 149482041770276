import { useMutation } from 'react-query'

import { getHolidaysfile } from 'services/worker/holidaysService'

import useErrorHandler from '../useErrorHandler'

const getMutationFunction = (mutationData) => {
  const { mutationMethod, mutationKey, workerId, fileFormat } = mutationData

  if (mutationMethod === 'GET' && mutationKey === 'holidaysHistoryFile')
    return getHolidaysfile(workerId, fileFormat)

  return null
}

export default function useHolidaysService({ mutationOptions = {} } = {}) {
  const { handleError } = useErrorHandler()

  const holidaysMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    holidaysMutation,
  }
}
