import { capitalize } from 'utils/general'

const filesNames = {
  socialSecurity: 'Social Security',
  payrollSummary: 'Payroll Summary',
  noveltiesReport: 'Novelties Report',
  individualPayslip: 'Individual Payslips',
  payslipsPdf: 'Payslips PDF',
  payslipsZip: 'Payslips ZIP',
  accounting: 'Accounting',
  bank: 'Bank',
}

const recurrentConcept = {
  salary_income: 'Salary Income',
  non_salary_income: 'Non Salary Income',
  deductions: 'Deductions',
  loans: 'Loans',
}

export const integrationEventsPayload = (args) => {
  const [event, complement] = args

  switch (event) {
    case 'Company Area Add':
    case 'Company Position Add':
    case 'Company Location Add':
    case 'Employee Income Certificate':
    case 'Ael Load Form':
    case 'Ael Paid Form':
    case 'Free Trial Ended':
      return { appcues: { event } }

    case 'Recurrent Add':
      return {
        appcues: {
          event: `Recurrent ${recurrentConcept[complement]} Add`,
        },
      }

    case 'Payroll Generate':
    case 'Payroll Bonus Add':
    case 'Payroll Overtime Add':
    case 'Payroll Loan Other Deduction Add':
    case 'Pay Payroll Old Period Edit':
    case 'Payslips Send Email':
    case 'Novelties File Upload':
    case 'Employee Termination':
    case 'Employee Labor Certificate':
      return { appcues: { event } }

    case 'Download File':
      return {
        appcues: {
          event: `Download ${filesNames[complement]} File`,
        },
      }

    // Create Employee
    case 'Employee Register':
      return {
        appcues: { event: `${event} ${capitalize(complement)}` },
      }

    // Company Events
    case 'Company Update':
      return {
        appcues: { event },
      }

    // Settings Events
    case 'Edit Settings':
      return {
        appcues: { event: `${event} ${complement}` },
      }

    // Collaborator Events
    case 'Invite Collaborator':
      return {
        appcues: {
          event: `Invite ${capitalize(complement)} Collaborator`,
        },
      }

    case 'Integration Done':
      return {
        appcues: {
          event: `${event} ${capitalize(complement)}`,
        },
      }

    // On Boarding
    case 'First Employee Create':
      return { appcues: { event } }

    default:
      return {
        appcues: { event: `Evento desconocido: ${event}` },
      }
  }
}

export default integrationEventsPayload
