import * as yup from 'yup'

import { isOrganizer } from 'utils/auth'
import { formatDisplayDateString } from 'utils/dateTime'

import * as routes from 'config/routes'

const optionsList = (worker) => ({
  contracts: {
    value: 'Contratos',
    to: routes.WORKER_CONTRACT_INDEX(worker.id),
  },
  contract_details: {
    value: 'Detalle de contrato',
    to: routes.WORKER_CONTRACT_DETAIL_INDEX(worker.id, worker.contract_id),
  },
  novelties: {
    value: 'Novedades de nómina',
    to: routes.WORKER_NOVELTIES_HISTORY(worker.id),
    state: {
      workerFullName: `${worker.name || ''} ${worker.last_name || ''}`,
    },
  },
  payments: {
    value: 'Pagos',
    to: routes.WORKER_PAYMENTS_HISTORY(worker.id),
  },
  loans: {
    value: 'Préstamos',
    to: routes.WORKER_LOAN_INDEX(worker.id),
  },
  wages: {
    value: 'Salarios',
    to: routes.WORKER_CONTRACT_WAGE_INDEX(worker.id, worker.contract_id),
  },
  holidays: {
    value: 'Vacaciones',
    to: routes.WORKER_HOLIDAYS_HISTORY(worker.id),
  },
  social_benefits: {
    value: 'Prestaciones sociales',
    to: routes.WORKER_CONTRACT_SOCIAL_BENEFITS_INDEX(
      worker.id,
      worker.contract_id
    ),
  },
  with_retention: {
    value: 'Retefuente',
  },
})

export const getHistoryOptions = (worker, avaliableOptions) => {
  let baseOptions = []

  if (!avaliableOptions) return baseOptions

  if (isOrganizer && !avaliableOptions.includes('contracts')) {
    baseOptions = ['contracts', ...avaliableOptions]
  }

  const options = avaliableOptions.map((elem) => {
    return { key: elem, ...optionsList(worker)[elem] }
  })

  return options
}

export const annualRetentionCertificatevalidationSchema = yup.object({
  contract_id: yup.string().nullable().required(),
  year: yup
    .string()
    .nullable()
    .when('contract_id', {
      is: (contractId) => contractId,
      then: yup.string().nullable().required(),
    }),
})

export const getContractFieldsOptions = (contracts = []) => {
  const contractFieldOptions = contracts.map((contract) => ({
    value: contract?.id,
    label: `${formatDisplayDateString(contract?.initial_day)}`,
  }))

  return contractFieldOptions
}

export const getYearFieldsOptions = (contracts = [], values = {}) => {
  if (values.contract_id) {
    const contractOptions = contracts.find(
      (contract) => contract.id === values.contract_id
    )

    const yearFieldOptions = contractOptions?.years.map((year) => ({
      label: String(year),
      value: year,
    }))

    return yearFieldOptions
  }

  return []
}
