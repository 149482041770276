import { createContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import AleluyaPerson from 'components/UI/AleluyaPerson'
import DescriptionCard, { CardContent } from 'components/UI/DescriptionCard'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { formatPeriodDateRange } from 'utils/dateTime'
import usePayrollsFileService from 'utils/hooks/payroll/fileService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import { getNoveltiesHistory } from 'services/payroll/fileService'
import periodService from 'services/payroll/periodService'
import { getNoveltiesHistoryWorker } from 'services/worker/workerService'

import copy from 'assets/images/three-dimensional-icons/copy.png'
import fileText from 'assets/images/three-dimensional-icons/file-text.png'

import * as routes from 'config/routes'

import HelperCard from '../Atoms/HelperCard'
import RangeDateCard from '../Atoms/RangeDateCard'
import { getActions, getColumnsData } from './helpers'

export const NoveltiesHistoryContext = createContext()

const NoveltiesHistory = () => {
  const { workerId, periodId } = useParams()
  const { state } = useLocation()
  const { enqueueDownload } = useDownloadManager()
  const { handleError } = useErrorHandler()
  const isFromPeriod = periodId !== undefined

  const [isLoading, setIsLoading] = useState(false)
  const [period, setPeriod] = useState(null)
  const [hasAnyNovelty, setHasAnyNovelty] = useState(false)
  const [activeNoveltyType, setActiveNoveltyType] =
    useState('contract_novelties')
  const [filterChanged, setFilterChanged] = useState(false)

  const { downloadFileMutation } = usePayrollsFileService()

  useEffect(() => {
    const fetchPeriod = async () => {
      setIsLoading(true)
      try {
        const data = await periodService.getById(periodId)

        setPeriod(data)
      } catch (error) {
        handleError(error)
      }

      setIsLoading(false)
    }

    if (periodId) fetchPeriod()
  }, [handleError, periodId])

  const handleChangeNovelty = (newTab) => {
    setActiveNoveltyType(newTab)
    setFilterChanged(true)
  }

  const onSuccessQuery = (filters) => {
    setHasAnyNovelty(filters.total_records > 0)
    setFilterChanged(false)
  }

  const fetchNovelties = (pageIndex, search) => {
    const options = {
      filter: activeNoveltyType,
      page: pageIndex + 1,
      search,
      async: false,
    }
    const queryOptionsPeriod = {
      queryKey: [
        'getAllNoveltiesHistoryPerPeriod',
        pageIndex,
        search,
        activeNoveltyType,
      ],
      queryFunction: () => getNoveltiesHistory(periodId, options),
      queryOptions: {
        onSuccess: ({ filters }) => {
          onSuccessQuery(filters)
        },
      },
    }

    const queryOptionsWorker = {
      queryKey: [
        'getAllNoveltiesHistoryPerWorker',
        pageIndex,
        search,
        activeNoveltyType,
      ],
      queryFunction: () => getNoveltiesHistoryWorker(workerId, options),
      queryOptions: {
        onSuccess: ({ filters }) => {
          onSuccessQuery(filters)
        },
      },
    }

    if (periodId) {
      return queryOptionsPeriod
    }
    if (workerId) {
      return queryOptionsWorker
    }

    return null
  }

  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()

  const handleClickNoveltyReport = async () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    if (!hasAnyNovelty) return

    let mutationKey = ''
    let options = {}
    let pathname = ''

    if (periodId) {
      mutationKey = 'noveltiesHistorySummaryPeriod'
      options = {
        file_format: 'xlsx',
      }
      pathname = `periods/${periodId}/novelties_history`
    } else {
      mutationKey = 'noveltiesHistorySummaryWorker'
      options = {
        file_format: 'xlsx',
      }
      pathname = `workers/${workerId}/novelties_history`
    }

    enqueueDownload({
      name: 'reporte de novedades',
      fileCode: 'novelties_report',
      firebasePath: pathname,
      service: () =>
        downloadFileMutation.mutateAsync({
          mutationMethod: 'GET',
          mutationKey,
          options,
          periodId,
          workerId,
        }),
      callback: () => {
        trackEvent(integrationEvent.DOWNLOAD_FILE, 'noveltiesReport')
      },
    })
  }

  const getDocumentTitle = () => {
    let title = 'Novedades '
    if (workerId) title += `de ${state?.workerFullName || 'trabajador'}`
    else if (isFromPeriod && period)
      title += ` del ${formatPeriodDateRange(
        period.initial_day,
        period.end_day
      )}`
    return title
  }

  const columns = getColumnsData(activeNoveltyType, isFromPeriod)

  return (
    <Page
      isLoading={isLoading}
      documentTitle={getDocumentTitle()}
      header={
        <Typography
          variant="h2"
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          Novedades
          {period
            ? ` del periodo ${formatPeriodDateRange(
                period.initial_day,
                period.end_day
              )}`
            : null}
          {workerId ? (
            <>
              {' de '}
              {state?.workerFullName ? (
                <Link to={routes.WORKER_SHOW(workerId)}>
                  {state?.workerFullName}
                </Link>
              ) : (
                'trabajador'
              )}
            </>
          ) : null}
        </Typography>
      }
      grid
    >
      <NoveltiesHistoryContext.Provider
        value={{
          activeNoveltyType,
          handleChangeNovelty,
          handleClickNoveltyReport,
          hasAnyNovelty,
        }}
      >
        {period ? (
          <Box
            sx={{
              gridColumn: '1 / -1',
            }}
          >
            <Box
              sx={(theme) => ({
                display: 'grid',
                columnGap: theme.spacing(4),
                rowGap: theme.spacing(3),
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridAutoRows: 'max-content',
                [theme.breakpoints.up('tablet')]: {
                  gridTemplateColumns: 'repeat(6, 1fr)',
                },
                [theme.breakpoints.up('desktop')]: {
                  rowGap: theme.spacing(1),
                  gridTemplateColumns: 'repeat(12, 1fr)',
                  gridTemplateRows: 'repeat(2, max-content)',
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  gridColumn: '1 / -1',
                  [theme.breakpoints.up('desktop')]: {
                    gridColumn: 'span 8',
                    gridRow: '1 / 3',
                  },
                })}
              >
                <DescriptionCard backgroundColor="complementary1.dark">
                  <Box
                    sx={(theme) => ({
                      width: '15.6875rem',
                      minHeight: '18rem',
                      height: '100%',
                      marginRight: theme.spacing(2),
                      display: {
                        mobile: 'none',
                        tablet: 'flex',
                      },
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingLeft: theme.spacing(2),
                    })}
                  >
                    <AleluyaPerson
                      color="accent3.main"
                      width={210}
                      height={280}
                      viewBox="0 0 350 400"
                    />
                  </Box>
                  <CardContent>
                    <Typography variant="h4">
                      {period
                        ? `Novedades del ${formatPeriodDateRange(
                            period.initial_day,
                            period.end_day
                          )}`
                        : ''}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({
                        marginBottom: theme.spacing(4),
                        marginTop: theme.spacing(1),
                      })}
                    >
                      Aquí encontrarás todas las novedades que tuvieron las
                      Personas de tu equipo en este periodo. Recuerda que puedes
                      editarlas ingresando a Liquidar nómina.
                    </Typography>
                    <Button
                      component={Link}
                      to={
                        state?.payPreviousPayroll
                          ? routes.PERIOD_PAY_PREVIOUS_PAYROLL(periodId)
                          : routes.PERIOD_GENERATED_PAYROLL()
                      }
                      variant="outlined"
                      startIcon={<Icon name="move-left" />}
                      size="medium"
                      sx={{ width: '10rem' }}
                    >
                      Regresar
                    </Button>
                  </CardContent>
                </DescriptionCard>
              </Box>
              <Box
                sx={(theme) => ({
                  gridColumn: '1 / -1',
                  [theme.breakpoints.up('tablet')]: {
                    gridColumn: 'span 3',
                  },
                  [theme.breakpoints.up('desktop')]: {
                    gridColumn: 'span 4',
                  },
                })}
              >
                <RangeDateCard
                  range={{
                    initialDay: period?.initial_day,
                    endDay: period?.end_day,
                  }}
                  color="accent3.light"
                  adornmentColor="accent3"
                  adornment="spring"
                />
              </Box>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: 1,
                  gridColumn: '1 / -1',
                  [theme.breakpoints.up('tablet')]: {
                    gridColumn: 'span 3',
                  },
                  [theme.breakpoints.up('desktop')]: {
                    gridColumn: '-1 / -5',
                    gridRow: '2 / span 1',
                  },
                })}
              >
                <HelperCard
                  title="¿Cómo editar novedades?"
                  link="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-registrar-las-novedades-de-mis-empleados"
                  color="accent4"
                  className="tutorial_1"
                  icon={
                    <Box
                      component="img"
                      loading="lazy"
                      src={copy}
                      alt="Aprende a editar las novedades"
                      width={64}
                      height={64}
                    />
                  }
                />
                <HelperCard
                  title="¿Qué son las novedades?"
                  link="https://ayuda.aleluya.com/portal/es/kb/liquidación-de-nómina/liquidar-nómina"
                  color="accent1.light"
                  className="tutorial_2"
                  icon={
                    <Box
                      component="img"
                      loading="lazy"
                      src={fileText}
                      alt="Conoce qué son las novedades"
                      width={64}
                      height={64}
                    />
                  }
                />
              </Box>
            </Box>
          </Box>
        ) : null}

        <Box
          sx={(theme) => ({
            gridColumn: '1 / -1',
            marginTop: theme.spacing(7),
          })}
        >
          <Table
            columns={columns}
            data={fetchNovelties}
            filterChanged={filterChanged}
            actions={getActions(activeNoveltyType, handleChangeNovelty)}
            options={{ version: { toolbar: 'v2' } }}
          />
        </Box>
      </NoveltiesHistoryContext.Provider>
    </Page>
  )
}

export default NoveltiesHistory
