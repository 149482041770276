import { differenceInCalendarDays, isAfter, parseISO } from 'date-fns'
import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Link } from '@mui/material'

import usePeriodEditingInactiveModal from 'components/Period/Payroll/Modals/usePeriodEditingInactiveModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Modal from 'components/UI/Modal/Modal'
import useWorker from 'components/Worker/useWorker'

import { getCompanyId } from 'utils/company'
import { formatValues, getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useContractsService from 'utils/hooks/worker/contracts'
import { filterContractFields, parseDate } from 'utils/worker'

import BaseSalaryField from '../../Wage/Fields/BaseSalary'
import WageCategoryField from '../../Wage/Fields/Category'
import TransportSubsidyField from '../../Wage/Fields/TransportSubsidy'
import CategoryField from '../Fields/Category'
import ContributorSubtype from '../Fields/ContributorSubtype'
import EndDayField from '../Fields/EndDay'
import HealthProviderField from '../Fields/HealthProvider'
import HighRiskPensionField from '../Fields/HighRiskPension'
import InitialDayField from '../Fields/InitialDay'
import PensionProviderField from '../Fields/PensionProvider'
import RiskTypeField from '../Fields/RiskType'
import SeveranceProviderField from '../Fields/SeveranceProvider'
import TermField from '../Fields/Term'
import getValidationSchema from './validationSchema'

const ContractModal = ({ state, handleClose, submitCallback }) => {
  const { worker, refreshWorker } = useWorker({ useCache: true })
  const { open, contract, workerId } = state
  const { showSuccessMessage } = useNotifications()
  const { contractsMutation } = useContractsService({
    queryOptions: { enabled: false },
  })
  const { handleError } = useErrorHandler()
  const confirm = useConfirm()
  const { openPeriodEditingInactiveModal } = usePeriodEditingInactiveModal()
  const queryClient = useQueryClient()
  const { subscription } = useSubscription()

  const isEditing = contract?.id

  const validationSchema = getValidationSchema(worker?.document_type)

  const submitNewContract = async (dirtyValues, form) => {
    contractsMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'createContract',
        workerId,
        contract: dirtyValues,
      },
      {
        onSuccess: () => {
          showSuccessMessage('Se ha creado el contrato correctamente')
          if (submitCallback) submitCallback()
          refreshWorker()
          if (subscription?.type === 'year')
            queryClient.invalidateQueries(['getSubscription', getCompanyId()])
          handleClose()
        },
        onError: (error) => {
          handleError(error, form, {
            errorsToNotificate: [{ object: 'worker_payment' }],
          })
        },
      }
    )
  }

  const handleSubmit = async (values, form) => {
    const dirtyValues = isEditing
      ? getDirtyValues(contract, values, validationSchema.fields)
      : formatValues(values, validationSchema.fields)

    const filteredDirtyValues = filterContractFields(
      dirtyValues,
      values.category,
      values.wage_category,
      isEditing
        ? values.contributor_subtype ||
            values?.contract_detail?.contributor_subtype ||
            worker?.contract_detail?.contributor_subtype
        : values.contributor_subtype
    )

    if (!isObjectEmpty(filteredDirtyValues)) {
      if (isEditing) {
        filteredDirtyValues.id = values.id

        contractsMutation.mutate(
          {
            mutationMethod: 'PATCH',
            contract: filteredDirtyValues,
          },
          {
            onSuccess: () => {
              showSuccessMessage('Se ha actualizado el contrato correctamente')
              if (submitCallback) submitCallback()
              refreshWorker()
              if (subscription?.type === 'year')
                queryClient.invalidateQueries([
                  'getSubscription',
                  getCompanyId(),
                ])
              handleClose()
            },
            onError: (error) => {
              if (error.errors[0].code === '0502') {
                openPeriodEditingInactiveModal()
              } else {
                handleError(error, form, {
                  // error returned when a user update the end_date of a contract but it can't
                  // be done because the deductions exceed the value to be paid to the worker
                  errorsToNotificate: [{ object: 'worker_payment' }],
                })
              }
            },
          }
        )
      } else {
        const { prevEndDay } = contract
        const initDay = parseISO(values.initial_day)

        const diff = differenceInCalendarDays(initDay, parseDate(prevEndDay))

        if (diff > 0) {
          confirm({
            type: 'info',
            description:
              'Están quedando días sin contrato para este trabajador. En esos días se contará al trabajador como inactivo.',
            okText: 'Volver a editar',
            cancelText: 'Guardar y continuar',
            onOk: () => form.setSubmitting(false),
            onCancel: () => {
              submitNewContract(filteredDirtyValues, form)
            },
          })
        } else {
          submitNewContract(filteredDirtyValues, form)
        }
      }
    }
  }

  return (
    <Formik
      initialValues={{
        ...contract,
        contributor_subtype: isEditing
          ? contract.contract_detail?.contributor_subtype
          : contract?.contributor_subtype,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formProps) => {
        const { values, handleSubmit: onSubmit } = formProps

        return (
          <Modal
            header={isEditing ? 'Editar contrato' : 'Crear contrato'}
            open={open}
            onOk={onSubmit}
            onCancel={handleClose}
            okText="Guardar"
            isLoading={contractsMutation.isLoading}
            paperSx={{
              maxWidth: '40.5rem',
              width: '100%',
            }}
          >
            <Form>
              <Box
                sx={(theme) => ({
                  gap: theme.spacing(3),
                  display: 'flex',
                  flexDirection: 'column',
                })}
              >
                <CategoryField optional={false} isEditing={isEditing} />
                <TermField
                  contractCategory={values.category}
                  optional={false}
                />
                <InitialDayField contract={contract} optional={false} />
                <EndDayField optional={!(values.term === 'fixed')} />
                <WageCategoryField
                  name="wage_category"
                  label="Salario base"
                  contractCategory={values.category}
                  optional={false}
                />
                <BaseSalaryField
                  contractCategory={values.category}
                  isEditing={isEditing}
                  optional={false}
                />
                <TransportSubsidyField contractCategory={values.category} />
                <ContributorSubtype
                  optional={false}
                  contractCategory={values.category}
                />
                <RiskTypeField
                  contractCategory={values.category}
                  isEditContract={isEditing}
                  optional={false}
                />
                <HighRiskPensionField />
                <HealthProviderField
                  contractCategory={values.category}
                  optional={false}
                />
                <PensionProviderField
                  documentType={worker?.document_type}
                  contractCategory={values.category}
                  contributorSubtype={values?.contributor_subtype}
                  optional={false}
                />
                <SeveranceProviderField
                  wageCategory={values.wage_category}
                  contractCategory={values.category}
                  optional={false}
                />
              </Box>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default ContractModal

export const validateCreateContract = ({
  currentContract,
  currentPeriod,
  handleRedirectToTermination,
  handleShowModal,
  confirm,
}) => {
  if (currentContract) {
    const {
      category,
      contract_category: contractCategory,
      terminated,
      end_day: endDay,
    } = currentContract

    const currentContractCategory =
      category?.value || contractCategory?.value || category

    const isEmployee = currentContractCategory === 'employee'

    if (isEmployee && !terminated) {
      confirm({
        title: 'Atención',
        description: (
          <>
            Esta persona tiene un contrato todavía activo. Si quieres crearle un
            contrato nuevo, primero liquida el actual. <br />
            Si quieres renovar este contrato por el mismo tipo de contrato sin
            liquidarlo, solo debes extender su fecha de terminación. Haz click{' '}
            <Link
              href="https://centro-de-ayuda-nominapp.groovehq.com/help/que-hacer-para-renovar-contrato-a-un-empleado"
              target="_blank"
              underline="hover"
            >
              aquí
            </Link>{' '}
            para saber más.
          </>
        ),
        type: 'warning',
        okText: 'Liquidar contrato actual',
        onOk: handleRedirectToTermination,
      })
    } else if (
      !isEmployee &&
      (!endDay || isAfter(endDay, currentPeriod.end_day))
    ) {
      confirm({
        title: 'Atención',
        description:
          'Revisa que la fecha de terminación del contrato actual sea menor o igual a la fecha final del periodo actual.',
        type: 'warning',
        hideCancelButton: true,
      })
    } else {
      handleShowModal()
    }
  } else {
    handleShowModal()
  }
}
