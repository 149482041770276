import { useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getAccountingCodes } from 'services/settings/accountingCodesService'
import { getAccountingInstitutionsCodes } from 'services/settings/accountingInstitutionCodesService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, category, categories } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'accountingCodes')
    return getAccountingCodes(category, categories)

  if (currentQueryKey === 'accountingInstitutionsCodes')
    return getAccountingInstitutionsCodes(category)

  return null
}

const useAccountingService = ({
  serviceParams = {},
  queryOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restAccountingResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => {
        handleError(error)
      },
      ...queryOptions,
    }
  )

  return {
    accountingQuery: {
      data,
      ...restAccountingResponse,
    },
  }
}

export default useAccountingService
