import { useCallback, useEffect, useState } from 'react'

import { Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'

import { isWorker } from 'utils/auth'
import { capitalize } from 'utils/general'
import usePayrollService from 'utils/hooks/payroll/payroll'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import fileService from 'services/payroll/fileService'

const initialState = {
  open: false,
  payroll: null,
  payslipTypes: [],
  payrollRanges: [],
  file_format: 'pdf',
  sendPaymentStubs: false,
}

/**
 * Used in conjunction with PayslipModal
 * @param {Object} period If not specified, the modal will only works for payroll payslips
 */
const usePayslipModal = (period, severanceDescription) => {
  const [state, setState] = useState({ ...initialState, period })
  const { showSuccessMessage } = useNotifications()
  const { enqueueDownload } = useDownloadManager()
  const { handleError } = useErrorHandler()
  const downloadURI = useDownloadURI()
  const confirm = useConfirm()
  const { workerMutation } = useWorkerService({
    queryOptions: {
      enabled: false,
    },
  })

  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })
  const { mutateAsync } = payrollMutation

  useEffect(() => {
    setState({ ...initialState, period })
  }, [period])

  const handleDownloadPayslip = useCallback(
    async ({
      // Temporal disable, is more complicated change that
      // eslint-disable-next-line no-shadow
      period,
      payroll,
      payslip_type: payslipType,
      payroll_range: payrollRange,
      file_format: fileFormat = 'pdf',
      sendPaymentStubs = false,
      whatsappNotification = false,
    }) => {
      // toggle ON global loading modal
      let response
      try {
        if (sendPaymentStubs) {
          if (payroll) {
            response = await payrollMutation.mutateAsync({
              mutationMethod: 'SEND_PAYSLIP',
              payrollId: payroll.id,
              payslipType,
              payrollRange,
              whatsappNotification,
            })
          } else {
            // from period
            response = await fileService.sendPeriodPayslips(period.id, {
              payslip_type: payslipType,
              whatsappNotification,
            })
          }

          const { message } = response
          showSuccessMessage(message)
          trackEvent(integrationEvent.PAYSLIPS_SEND_EMAIL)
        } else if (payroll) {
          if (isWorker()) {
            response = await workerMutation.mutateAsync({
              mutationMethod: 'GET',
              payrollId: payroll.id,
              payslip_type: payslipType,
            })
          } else {
            response = await payrollMutation.mutateAsync({
              mutationMethod: 'GET_PAYSLIP',
              payrollId: payroll.id,
              payslipType,
              payrollRange,
            })
          }

          const { file } = response
          if (file) downloadURI(file)

          trackEvent(integrationEvent.DOWNLOAD_FILE, 'individualPayslip')
        } else {
          const callback = () => {
            const format = capitalize(fileFormat)

            trackEvent(integrationEvent.DOWNLOAD_FILE, `payslips${format}`)
          }

          enqueueDownload({
            name: 'colillas de pago',
            fileCode: 'period_payslips',
            firebasePath: `periods/${period.id}/payslips`,
            service: () =>
              fileService.getPeriodPayslips(period.id, {
                file_format: fileFormat,
                payslip_type: payslipType,
                payroll_range: payrollRange,
              }),
            callback,
            namePlural: true,
            nameGender: 'female',
          })
        }
        // toggle OFF global loading modal
      } catch (error) {
        handleError(error)
      }
    },
    [
      downloadURI,
      enqueueDownload,
      handleError,
      payrollMutation,
      showSuccessMessage,
      workerMutation,
    ]
  )

  /**
   * Required options:
   *
   * For period payslip: Do not specify payroll object and file_format is optional, default is 'pdf'
   *
   * For payroll payslip: Just specify payroll option and payslipTypes is optional
   *
   * @param {Object} options
   */
  const openPayslipModal = useCallback(
    async ({
      payroll,
      payslipTypes,
      file_format: fileFormat = 'pdf',
      sendPaymentStubs,
      whatsappNotification,
    } = {}) => {
      // toggle ON global loading modal
      let newState

      try {
        if (payroll) {
          newState = {
            ...state,
            payroll,
          }

          if (payslipTypes) {
            newState.payslipTypes = payslipTypes
          } else {
            await mutateAsync(
              {
                mutationMethod: 'GET_PAYSLIP_OPTIONS',
                payrollId: payroll.id,
              },
              {
                onSuccess: ({ data }) => {
                  newState.payslipTypes = data.payslip_type
                  newState.payrollRanges = data.payroll_range
                },
              }
            )
          }
        } else if (period) {
          // from period
          const { payslip_type: payslipType } =
            await fileService.getPayslipOptions(period.id)

          newState = {
            ...state,
            payslipTypes: payslipType,
            file_format: fileFormat,
          }
        } else {
          throw new Error(
            'No payroll or period data specified for PayslipsModal'
          )
        }

        if (
          newState.payrollRanges.length === 0 &&
          newState.payslipTypes.length === 0
        ) {
          const downloadPayslipOptions = {
            payroll,
            period,
            file_format: fileFormat,
            sendPaymentStubs,
            whatsappNotification,
          }

          if (sendPaymentStubs) {
            confirm({
              type: 'warning',
              title: `Enviar colilla${
                !payroll ? 's a todas las personas' : ''
              }`,
              description: (
                <>
                  {severanceDescription}
                  <Typography>
                    {!payroll ? 'A cada persona de tu nómina le' : 'Le'} llegará
                    la colilla{' '}
                    {whatsappNotification
                      ? 'al WhatsApp asociado al número de celular'
                      : 'al correo electrónico'}{' '}
                    que hayas registrado en su perfil.
                  </Typography>
                </>
              ),
              okText: `Enviar colilla${!payroll ? 's' : ''}`,
              onOk: async () => {
                const download = await handleDownloadPayslip(
                  downloadPayslipOptions
                )
                return download
              },
            })
          } else {
            await handleDownloadPayslip(downloadPayslipOptions)
          }

          return
        }

        newState.sendPaymentStubs = sendPaymentStubs
        newState.open = true

        setState({ ...newState, whatsappNotification })
      } catch (error) {
        handleError(error)
      }
      // toggle OFF global loading modal
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateAsync, period, state]
  )

  const closePayslipModal = useCallback(() => {
    setState({ ...initialState, period })
  }, [period])

  return {
    payslipModalState: state,
    openPayslipModal,
    closePayslipModal,
    handleDownloadPayslip,
  }
}

export default usePayslipModal
