import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { Link } from 'react-router-dom'

import { Menu, MenuItem } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import AnnualRetentionCertificateModal from './AnnualRetentionCertificateModal'
import { getHistoryOptions } from './helpers'
import useAnualRetentionCertificateModal from './useAnnualRetentionCertificate'

const HistoryMenu = ({ worker, workerOptions }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workerHistoryMenu',
  })

  const closePopup = () => popupState.close()

  const {
    openAnnualRetentionCertificateModal,
    closeAnnualRetentionCertificateModal,
    annualRetentionCertificateModalState,
  } = useAnualRetentionCertificateModal()

  const historyOptions = getHistoryOptions(
    worker,
    workerOptions?.history_options
  )

  return (
    <>
      <Button
        endIcon={<Icon name="chevron-down" />}
        {...bindTrigger(popupState)}
        variant="outlined"
        size="large"
        sx={{ width: { mobile: 1, tablet: 'auto' } }}
      >
        Historiales
      </Button>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {historyOptions.map(({ key, to, state, value }) => {
          return key !== 'with_retention' ? (
            <MenuItem key={key} component={Link} to={to} state={state}>
              {value}
            </MenuItem>
          ) : (
            <MenuItem
              key={key}
              onClick={() => {
                openAnnualRetentionCertificateModal(
                  workerOptions?.contracts_with_retention
                )
                closePopup()
              }}
            >
              {value}
            </MenuItem>
          )
        })}
      </Menu>
      {annualRetentionCertificateModalState.open ? (
        <AnnualRetentionCertificateModal
          state={annualRetentionCertificateModalState}
          handleClose={closeAnnualRetentionCertificateModal}
        />
      ) : null}
    </>
  )
}

export default HistoryMenu
