import { useState } from 'react'

export default function useAnualRetentionCertificateModal() {
  const [state, setState] = useState({
    open: false,
    contracts: [],
  })

  const openAnnualRetentionCertificateModal = (contracts) => {
    setState({
      open: true,
      contracts,
    })
  }

  const closeAnnualRetentionCertificateModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  return {
    openAnnualRetentionCertificateModal,
    closeAnnualRetentionCertificateModal,
    annualRetentionCertificateModalState: state,
  }
}
