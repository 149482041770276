export default {
  AEL_LOAD_FORM: 'Ael Load Form',
  AEL_PAID_FORM: 'Ael Paid Form',
  COMPANY_UPDATE: 'Company Update',
  COMPANY_ADD_LOCATION: 'Company Location Add',
  DOWNLOAD_FILE: 'Download File',
  EMPLOYEE_REGISTER: 'Employee Register',
  EMPLOYEE_LABOR_CERTIFICATE: 'Employee Labor Certificate',
  EMPLOYEE_INCOME_CERTIFICATE: 'Employee Income Certificate',
  EMPLOYEE_ADD_RECURRENT_CONCEPT: 'Recurrent Add',
  EMPLOYEE_TERMINATION: 'Employee Termination',
  PAYROLL_GENERATE: 'Payroll Generate',
  PAYROLL_ADD_BONUS: 'Payroll Bonus Add',
  PAYROLL_ADD_OVERTIME: 'Payroll Overtime Add',
  PAYROLL_ADD_LOANS_AND_DEDUCTIONS: 'Payroll Loan Other Deduction Add',
  PAYROLL_OLD_PERIOD: 'Pay Payroll Old Period Edit',
  PAYSLIPS_SEND_EMAIL: 'Payslips Send Email',
  NOVELTIES_FILE_UPLOAD: 'Novelties File Upload',
  SIGN_UP: 'Sign Up',
  EDIT_SETTINGS: 'Edit Settings',
  INTEGRATION_DONE: 'Integration Done',
  INVITE_COLLABORATOR: 'Invite Collaborator',
  FIRST_EMPLOYEE: 'First Employee Create',
}
