import { getUserEmail, getUserName, isWorker } from 'utils/auth'
import { getCompanyId } from 'utils/company'

const onZohoAPIReady = () => {
  if (window.$zoho && window.$zoho.salesiq) {
    if (window.$zoho.salesiq.visitor) {
      window.$zoho.salesiq.visitor.name(getUserName())
      window.$zoho.salesiq.visitor.email(getUserEmail())
      window.$zoho.salesiq.visitor.info({
        'Id de la empresa': getCompanyId(),
        'Es perfil empleado': isWorker(),
      })
    }
    if (window.$zoho.salesiq.floatwindow) {
      window.$zoho.salesiq.floatwindow.onlinetitle('¡Estamos para ayudarte! ❤️')
      window.$zoho.salesiq.floatwindow.offlinetitle('¡Volvemos pronto! 🤗')
      window.$zoho.salesiq.floatwindow.visible('hide')
      window.$zoho.salesiq.floatbutton.visible('hide')
    }
  }
}

export const zohoScript = {
  src: 'https://salesiq.zoho.com/widget',
  child: () => {
    if (window.$zoho && window.$zoho.salesiq) {
      window.$zoho.salesiq.ready(onZohoAPIReady)
    } else {
      window.$zoho = window.$zoho || {}
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: [
          'e50515f4-aceb-48f8-a140-adf8499cfe81',
          '56280701-8155-4f2b-8593-d42dbc252bfd',
        ].includes(getCompanyId())
          ? process.env.REACT_APP_AI_ZOHO_WIDGET_CODE
          : process.env.REACT_APP_ZOHO_WIDGET_CODE,
        values: {},
        ready: onZohoAPIReady,
      }
    }
  },
  async: true,
  defer: true,
}
