import { Form, Formik } from 'formik'

import { Box, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import useContractsService from 'utils/hooks/worker/contracts'

import {
  annualRetentionCertificatevalidationSchema,
  getContractFieldsOptions,
  getYearFieldsOptions,
} from './helpers'

export default function AnnualRetentionCertificateModal({
  state,
  handleClose,
}) {
  const { contracts } = state

  const { enqueueDownload } = useDownloadManager()
  const { contractsMutation } = useContractsService({
    queryOptions: {
      enabled: false,
    },
  })

  const contractFieldOptions = getContractFieldsOptions(contracts)

  const onSubmit = (values) => {
    const { contract_id: contractId, year } = values

    enqueueDownload({
      name: 'resumen anual de retefuente',
      fileCode: 'payroll_retention_summary_file',
      firebasePath: `contracts/${contractId}/contract_retention_yearly_file`,
      service: () =>
        contractsMutation.mutateAsync(
          {
            mutationMethod: 'GET',
            mutationKey: 'downloadRetentionFile',
            contractId,
            year,
          },
          {
            onSuccess: () => {
              handleClose()
            },
          }
        ),
    })
  }

  return (
    <Modal
      header="Reporte de retención en la fuente"
      hideFooter
      onCancel={handleClose}
    >
      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(3),
        })}
      >
        <Typography color="black.dark">
          Selecciona el año y el contrato para generar el reporte de manera
          correcta.
        </Typography>
        <Formik
          onSubmit={onSubmit}
          validationSchema={annualRetentionCertificatevalidationSchema}
          initialValues={{
            contract_id: '',
            year: '',
          }}
        >
          {({ handleSubmit, values }) => {
            const yearFieldOptions = getYearFieldsOptions(contracts, values)

            return (
              <Form>
                <Box
                  sx={({ spacing }) => ({
                    display: 'flex',
                    gap: spacing(3),
                  })}
                >
                  <FormField
                    label="Contrato"
                    name="contract_id"
                    variant="select"
                    options={contractFieldOptions}
                    optional={false}
                  />
                  <FormField
                    label="Año del certificado"
                    name="year"
                    variant="select"
                    options={yearFieldOptions}
                    optional={false}
                  />
                </Box>

                <Box
                  sx={({ spacing }) => ({
                    display: 'flex',
                    gap: spacing(2),
                    marginTop: spacing(4),
                  })}
                >
                  <Button
                    onClick={handleSubmit}
                    loading={contractsMutation.isLoading}
                  >
                    Generar archivo
                  </Button>
                  <Button
                    onClick={handleClose}
                    disabled={contractsMutation.isLoading}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Modal>
  )
}
